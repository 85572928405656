$tcs-yellow: #ffeb02;
$tcs-yellow-dark: #e5d303;
$tcs-black: #222222;

.notice-theme-tcs {
  background-color: $tcs-yellow;
  color: $tcs-black;
  padding: $spacing-unit-small $spacing-unit-default;
  font-weight: 700;

  div:first-child {
    width: 20%;
    padding-right: 15px;

    img {
      width: 100%;
    }
  }

  div:nth-child(2) {
    width: 80%;
  }
}

.btn--notice-theme-tcs {
  color: $tcs-black;
  border-color: $tcs-black;

  &:hover {
    background-color: $tcs-yellow-dark;
  }
}
