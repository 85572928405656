.lex-markdown {
  p {
    margin-bottom: 0;
  }
  ul, ol {
    margin-top: 0;
  }
  li+li {
    border-top: 0;
  }
}
