.resultBoxLinks {
    margin-top: 1em;
    word-wrap: break-word;
}
.resultBoxLinks img {
    max-width: 100%;
    height: auto;
}
.resultBoxLinks li img {
    float: right;
    transition: transform .3s ease;
}
.resultBoxLinks li img {
    float: right;
    max-height: 100px;
    max-width: 120px;
    margin-bottom: 15px;
    margin-top: 5px;
    margin-left: 5px;
}
.icon.icon--beta {
  vertical-align: -15px;
  position: relative;
  top: -14px;
  left: -13px;
}
.gpt {
  &.icon--laptop_help {
    fill: #fff;

    &.search-bar__icon__right {
        right: 12px;
        left: auto;
        display: none;
    }
  }
}
