/*----------------------------------------*\
  SLIDER
\*----------------------------------------*/


.slider {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.slider__body {
  width: 100%;
  flex: 0 0 auto;
  position: relative;
}

.slider__item {
  flex: 1 1 auto;
  opacity: 0;
  transition: opacity 0.3s ease;

  &:not(.active) {
    display: none;
  }

  &.active {
    opacity: 1;
  }

  &.leaving {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    opacity: 0;
  }
}

/**
 * Nav
 */

.slider-nav {
  margin-top: $spacing-unit-default;
  width: 100%;
  display: flex;

  flex-direction: row-reverse;
  justify-content: space-between;
  > li {
    display: inline-block;

    &:not(:last-child) {
      margin-left: $spacing-unit-default;
    }
  }
}
