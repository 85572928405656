/*----------------------------------------*\
  SITE SPECIFIC STYLES
\*----------------------------------------*/

/**
 * Import settings & tools from Styleguide
 */

@import '../../../node_modules/@lex4you/styleguide/src/assets/styleguide/styles/settings/settings';


/**
 * Components
 */

@import 'site/components/site-anchor';
@import 'site/components/site-search';
@import 'site/components/site-user-form';
@import 'site/components/site-markdown';
@import 'site/components/site-gpt';

@import 'flatpickr/dist/flatpickr.min.css';
@import 'site/components/site-slider';
